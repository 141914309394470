import axiosClient from '@/api/axiosInstance'
import { USER } from '@/api/endpoints'
import axios, { AxiosResponse } from 'axios'
import { FetchUsesrsResponse, UpdateUserInfo, UserInfo } from '../types/user.types'

/**
 * Fetches a list of users from the system.
 * @param search Optional search term to filter users.
 * @returns A list of users.
 */
export const fetchUser = async (search?: string): Promise<UserInfo[]> => {
  const api = axiosClient()
  try {
    const response: AxiosResponse<FetchUsesrsResponse> = await api.get<FetchUsesrsResponse>(
      USER.USER_LIST,
      {
        params: search ? { search } : {}
      }
    )
    console.log(response.data.results)
    // Verify that the fetched data is an array before using map on it
    if (Array.isArray(response.data.results)) {
      // Properly access response.data.data and return the array inside the "data" field
      return response.data.results
    } else {
      // If no services exist or data is not an array, return an empty array
      return []
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      // Return an empty array if no services are found (404)
      return []
    }
    throw new Error('Failed to fetch user data')
  }
}

/**
 * Fetches the user profile information.
 * @param {string} id - The user ID for which to fetch the profile
 * @returns {Promise<AxiosResponse<UserInfo>>} The user profile data.
 */
export const getUserInfo = async (id: string): Promise<AxiosResponse<UserInfo>> => {
  const api = axiosClient()
  const response = await api.get(USER.profile(id))
  return response
}

/**
 * Updates a user's information by ID.
 * @param id - The ID of the user to update.
 * @param payload - The new data to update for the user.
 * @returns The updated user data.
 */
export const updateUserData = async (
  id: string,
  payload: UpdateUserInfo
): Promise<AxiosResponse<UpdateUserInfo>> => {
  const api = axiosClient()
  const response = await api.put(USER.updateUserInfo(id), payload)
  return response
}
