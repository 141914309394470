import DashboardLayout from '@/modules/core/layouts/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import CustomTable from '@/modules/core/table/CustomTable'
import { fetchUser } from '../services/user.services'
import { useNavigateTo } from '@/hooks/useNavigateTo'
import { UserInfo } from '../types/user.types'

type TableData = Record<string, string>

/**
 * UserListPage Component
 *
 * Renders the User page using the DashboardLayout and UserForm components.
 *
 * @returns {JSX.Element} The rendered User page.
 */
const UserListPage: React.FC = () => {
  const { t } = useTranslation('user')
  const [data, setData] = useState<TableData[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigateTo()
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Set an appropriate default

  const columns = [
    {
      key: 'arabic_name',
      header: t('users'),
      render: (value: string, row: TableData) => (
        <div className="flex items-center justify-center gap-2 ltr:flex-row-reverse">
          {row.logo && (
            <img src={row.logo} alt={value} className="w-8 h-8 rounded-full object-cover" />
          )}
          <span>{value}</span>
        </div>
      )
    },
    { key: 'clo_email', header: t('email') },
    { key: 'clo_name', header: t('contact_officer_name') }
  ]

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true)
        const users = (await fetchUser(searchTerm)) as unknown as UserInfo[]
        setData(
          users.map((user) => ({
            user_id: user.user_id,
            arabic_name: user.arabic_name,
            clo_email: user.clo_email,
            clo_name: user.clo_name,
            logo: user.logo
          })) as TableData[]
        )
      } catch {
        console.log(' ')
      } finally {
        setLoading(false)
      }
    }

    void loadUsers()
  }, [t, searchTerm])

  const handleEdit = (row: TableData) => {
    navigate(`users/profile/update/${row.user_id}`)
    console.log('update')
  }

  const handleView = (row: TableData) => {
    navigate(`users/profile/retrieve-info/${row.user_id}`)
    console.log(row)
  }
  const newUser = () => {
    navigate('users/register-company')
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (search: string) => {
    setSearchTerm(search)
    setCurrentPage(1)
  }

  return (
    <DashboardLayout>
      <div>
        <h2 className="text-start text-2xl ">{t('manageUser')}</h2>
        <div className="flex w-full lg:justify-end  mt-3">
          <Button type="primary" style={{ marginBottom: '16px' }} onClick={newUser}>
            + {t('addNewUser')}
          </Button>
        </div>
        <CustomTable
          data={data}
          columns={
            columns as {
              key: keyof TableData
              header: string
              width?: string
              render?: (value: unknown, row: TableData) => React.ReactNode
            }[]
          }
          totalCount={data.length}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onSearchChange={handleSearchChange}
          isLoading={loading}
          actionConfig={{
            type: 'viewAndEdit',
            onEdit: handleEdit,
            onView: handleView
          }}
          filters={[{ key: 'type', label: t('rule') }]}
        />
      </div>
    </DashboardLayout>
  )
}

export default UserListPage
