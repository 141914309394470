import React, { lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { StyleProvider } from '@ant-design/cssinjs'
import './antd/overrides.css'
import './index.css'
import useLocalizeDocumentAttributes from './i18n/useLocalizeDocumentAttributes'
import LanguageProvider from './context/LanguageProvider'
import { themeConfig } from './antd/themeConfig'
import { NotificationProvider } from '@/modules/core/contexts/NotificationContext'
import { AuthProvider } from '@/modules/core/contexts/AuthContext'

// import ThemeProvider from './context/ThemeProvider'
import ProtectedRoute from './modules/core/components/Routers/ProtectedRoute'
import { useTranslation } from 'react-i18next'
import { ConfigProvider } from 'antd'
import UserListPage from './modules/user/pages/UserListPage'
import AddSeasonPage from './modules/season/pages/AddSeasonPage'
import AdminRoute from './modules/core/components/Routers/AdminRoute'
import CompnayRoute from './modules/core/components/Routers/CompnayRoute'
import UnderConstructionPage from './modules/core/pages/UnderConstructionPage'

// Lazy loading components using aliases
const LoginPage = lazy(() => import('@/modules/auth/pages/LoginPage'))
const OtpVerificationPage = lazy(() => import('@/modules/auth/pages/OtpVerificationPage'))
const NotFound = lazy(() => import('./modules/core/pages/NotFound'))
const LandingPage = lazy(() => import('./modules/core/pages/LandingPage'))
const TasksPage = lazy(() => import('./modules/task/pages/TasksPage'))
const TaskDetailsPage = lazy(() => import('./modules/task/pages/TaskDetailsPage'))
const PlansPage = lazy(() => import('@/modules/plan/pages/PlansPage'))
const RegisterNewCompany = lazy(() => import('@/modules/user/pages/registerNewCompany'))
const AssignPlan = lazy(() => import('@/modules/plan/pages/AssignPlanPage'))
const EditCompany = lazy(() => import('@/modules/company/pages/EditCompany'))
const UserInfoPage = lazy(() => import('@/modules/user/pages/UserInfoPage'))
const EditUserInfoPage = lazy(() => import('@/modules/user/pages/EditUserInfoPage'))
const TasksTrackingPage = lazy(() => import('@/modules/task/pages/TasksTrackingPage'))
const TaskStatusPageAdmin = lazy(() => import('./modules/task/pages/task-status-admin'))
const CompanyTasksPage = lazy(() => import('@/modules/task/pages/CompanyTasksPage'))

const NotificationsPage = lazy(() => import('@/modules/core/pages/NotificationsPage'))
const NotificationDetailsPage = lazy(() => import('@/modules/core/pages/NotificationDetailsPage'))

const CompanyCompleteInfo = lazy(() => import('@/modules/company/pages/CompanyCompleteInfoPage'))
const CompanyListPage = lazy(() => import('@/modules/company/pages/CompanyListPage'))
const CompanyInfoPage = lazy(() => import('@/modules/company/pages/CompanyInfoPage'))
const SeasonPage = lazy(() => import('./modules/season/pages/SeasonPage'))
const SeasonListPage = lazy(() => import('./modules/season/pages/SeasonListPage'))
const AssignSeasonPage = lazy(() => import('./modules/season/pages/AssignSeasonPage'))
const AddBatchPage = lazy(() => import('./modules/season/pages/AddBatchPage'))
const AddPlan = lazy(() => import('@/modules/plan/pages/addPlan'))
const AddTask = lazy(() => import('@/modules/plan/pages/addTask'))
const CompanyProfilePage = lazy(() => import('@/modules/company/pages/CompanyProfilePage'))
const EditCompanyProfile = lazy(() => import('@/modules/company/pages/EditCompanyProfilePage'))
const AddCompanyPage = lazy(() => import('@/modules/company/pages/AddCompanyPage'))

// Supported languages
const SUPPORTED_LANGUAGES = ['ar', 'en']
const DEFAULT_LANGUAGE = 'ar'

/**
 * Main application component that sets up routing and localization.
 * @returns {React.JSX.Element} The main application component wrapped with necessary providers.
 * @description
 * This component configures:
 * - Localization of document attributes using `useLocalizeDocumentAttributes`.
 * - Protected, public, and auth routes with language-prefixed paths.
 * - Default language redirection for unsupported paths.
 * - React Router for handling navigation with suspense fallback for lazy-loaded components.
 * - Theme configuration using Ant Design's ConfigProvider.
 */
function App(): React.JSX.Element {
  useLocalizeDocumentAttributes()
  const { i18n } = useTranslation()
  const currentLang = localStorage.getItem('i18nLang') ?? i18n.resolvedLanguage

  // Define the protected routes that should be available in each language
  const protectedRoutes = [
    { path: 'notifications', element: <NotificationsPage /> },
    { path: 'notifications/:id', element: <NotificationDetailsPage /> },

    //under construction
    { path: '', element: <UnderConstructionPage /> },
    { path: 'settings', element: <UnderConstructionPage /> }
  ]

  const adminRouts = [
    { path: 'plans', element: <PlansPage /> },
    { path: 'plans/add-plan', element: <AddPlan /> },
    { path: 'plans/add-plan/add-task', element: <AddTask /> },
    { path: 'users', element: <UserListPage /> },
    { path: 'users/register-company', element: <RegisterNewCompany /> },
    { path: 'seasons', element: <SeasonPage /> },
    { path: 'seasons/add-season', element: <AddSeasonPage /> },
    { path: 'company/update/:id', element: <EditCompany /> },
    { path: 'company', element: <CompanyListPage /> },
    { path: 'company/company-info/:id', element: <CompanyInfoPage /> },
    { path: 'company/assign-plan', element: <AssignPlan /> },
    { path: 'users/profile/retrieve-info/:id', element: <UserInfoPage /> },
    { path: 'users/profile/update/:id', element: <EditUserInfoPage /> },
    { path: 'company/add-company', element: <AddCompanyPage /> },
    { path: 'tasks-tracking', element: <TasksTrackingPage /> },
    { path: 'task-tracking/:taskId', element: <TaskStatusPageAdmin /> }, // for admin to see task status
    { path: 'tasks-tracking/compnay-task/:id/:biz_model', element: <CompanyTasksPage /> }
  ]

  // Define the public routes that should be available in each language
  const publicRoutes = [
    { path: 'landing-page', element: <LandingPage /> },
    { path: 'company/complete-info', element: <CompanyCompleteInfo /> }
  ]

  // Define auth routes
  const authRoutes = [
    { path: 'login', element: <LoginPage /> },
    { path: 'verify-login', element: <OtpVerificationPage /> }
  ]

  // Define company routes
  const companyRoutes = [
    { path: 'tasks', element: <TasksPage /> },
    { path: 'tasks/:taskId', element: <TaskDetailsPage /> },
    { path: 'seasons-list', element: <SeasonListPage /> },
    { path: 'seasons-list/assign-season', element: <AssignSeasonPage /> },
    { path: (id: string) => `seasons-list/assign-batch/${id}`, element: <AddBatchPage /> },
    { path: 'profile', element: <CompanyProfilePage /> },
    { path: 'profile/edit', element: <EditCompanyProfile /> }
  ]

  return (
    //Style provider: to override antd styles by tailwind styles, for example <button className="bg-accent"> will not override antd button bg color without this provider
    <StyleProvider layer>
      {/* Theme provider: feeds the app with antd config, light and dark theme, default colors*/}
      {/* <ThemeProvider> */}
      <ConfigProvider theme={themeConfig}>
        <AuthProvider>
          {' '}
          {/* Add the AuthProvider */}
          <NotificationProvider>
            <Router>
              <LanguageProvider>
                <Routes>
                  {/* Redirect root to default language */}
                  <Route
                    path="/"
                    element={<Navigate to={`/${currentLang ?? DEFAULT_LANGUAGE}`} />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={`/${currentLang ?? DEFAULT_LANGUAGE}`} replace />}
                  />
                  {/* Generate routes for each supported language */}
                  {SUPPORTED_LANGUAGES.map((lang) => (
                    <React.Fragment key={`lang-group-${lang}`}>
                      {/* Protected routes */}
                      {protectedRoutes.map((route) => (
                        <Route
                          key={`${lang}-${route.path}`}
                          path={`/${lang}${route.path ? `/${route.path}` : ''}`}
                          element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                        />
                      ))}

                      {SUPPORTED_LANGUAGES.map((lang) =>
                        adminRouts.map((route) => (
                          <Route
                            key={`${lang}-${route.path}`}
                            path={`/${lang}/${route.path}`}
                            element={<AdminRoute>{route.element}</AdminRoute>}
                          />
                        ))
                      )}

                      {/* Public routes */}
                      {publicRoutes.map((route) => (
                        <Route
                          key={`${lang}-${route.path}`}
                          path={`/${lang}/${route.path}`}
                          element={route.element}
                        />
                      ))}

                      {/* Catch-all route for this language */}
                      <Route key={`${lang}-not-found`} path={`/${lang}/*`} element={<NotFound />} />
                    </React.Fragment>
                  ))}

                  {/* Auth routes */}
                  {SUPPORTED_LANGUAGES.map((lang) =>
                    authRoutes.map((route) => (
                      <Route
                        key={`${lang}-${route.path}`}
                        path={`/${lang}/${route.path}`}
                        element={route.element}
                      />
                    ))
                  )}

                  {/* Company Routes */}
                  {SUPPORTED_LANGUAGES.map((lang) =>
                    companyRoutes.map((route) => {
                      const rawPath =
                        typeof route.path === 'function' ? route.path(':id') : route.path
                      return (
                        <Route
                          key={`${lang}-${rawPath}`}
                          path={`/${lang}/${rawPath}`}
                          element={<CompnayRoute>{route.element}</CompnayRoute>}
                        />
                      )
                    })
                  )}
                </Routes>
              </LanguageProvider>
            </Router>
          </NotificationProvider>
        </AuthProvider>
        {/* </ThemeProvider> */}
      </ConfigProvider>
    </StyleProvider>
  )
}

export default App
