import axiosClient from '@/api/axiosInstance'
import {
  ApiNotification,
  NotificationsResponse,
  NotificationType,
  TableNotification,
  NotificationTypeEnum
} from '@/modules/core/types/notification.types'

import { NOTIFICATION, SEASON } from '@/api/endpoints'

// Get API client instance
const apiClient = axiosClient()

/**
 * Formats a date string to Gregorian format
 * @param {string} dateString - ISO date string from API
 * @returns {string} Formatted Gregorian date string (MM/DD/YYYY)
 */
const formatGregorianDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

/**
 * Formats a date string to Hijri format
 * @returns {string} Formatted Hijri date string
 */
const formatHijriDate = (): string => {
  // Placeholder for Hijri date conversion
  return 'Hijri date placeholder'
}

/**
 * Fetches all notifications from the API
 * @returns {Promise<NotificationsResponse>} Promise containing the notifications data with pagination
 */
export const fetchNotifications = async (): Promise<NotificationsResponse> => {
  try {
    // Use apiClient instead of axios
    const response = await apiClient.get<NotificationsResponse>(NOTIFICATION.getNotifications)

    // Check if response is not JSON (e.g., HTML response from auth redirect)
    if (typeof response.data === 'string' || typeof response.data !== 'object') {
      console.error('Invalid API response format:', response.data)
      // Return an empty valid response structure to prevent errors
      return {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    }

    // Ensure that results always exists, even if the API doesn't return it
    const responseData = response.data
    if (!Array.isArray(responseData.results)) {
      console.warn('API response missing results array, using empty array instead')
      return {
        ...responseData,
        results: []
      }
    }

    return responseData
  } catch (error) {
    console.error('Error fetching notifications:', error)
    // Return an empty valid response structure instead of throwing
    return {
      count: 0,
      next: null,
      previous: null,
      results: []
    }
  }
}

/**
 * Fetches details for a specific notification by ID
 * Note: This API call will automatically mark the notification as read
 * @param {string} id - Notification ID
 * @returns {Promise<ApiNotification>} Promise containing the notification details
 */
export const fetchNotificationDetails = async (id: string): Promise<ApiNotification> => {
  try {
    // Use apiClient instead of axios
    const response = await apiClient.get<ApiNotification>(NOTIFICATION.getNotificationDetails(id))

    // Check if response is not a valid notification object
    if (typeof response.data === 'string' || !response.data.id) {
      console.error(`Invalid notification details response for ID ${id}:`, response.data)
      throw new Error('Invalid notification response')
    }

    return response.data
  } catch (error) {
    console.error(`Error fetching notification details for ID ${id}:`, error)
    throw error
  }
}

/**
 * Approves or rejects a season's number of pilgrims
 * @param {string} seasonId - The ID of the season
 * @param {boolean} isApproved - Whether to approve (true) or reject (false) the pilgrim numbers
 * @returns {Promise<{id: string, is_approved: boolean}>} Promise containing the result of the approval
 */
export const approveSeason = async (
  seasonId: string,
  isApproved: boolean
): Promise<{ id: string; is_approved: boolean }> => {
  try {
    const response = await apiClient.put<{ id: string; is_approved: boolean }>(
      SEASON.approveSeason(seasonId),
      {
        is_approved: isApproved
      }
    )

    if (typeof response.data !== 'object') {
      console.error('Invalid API response format for season approval:', response.data)
      throw new Error('Invalid season approval response')
    }

    return response.data as { id: string; is_approved: boolean }
  } catch (error) {
    console.error(`Error approving season ${seasonId}:`, error)
    throw error
  }
}

/**
 * Fetches all available notification types
 * @returns {Promise<NotificationType[]>} Promise containing array of notification types
 */
export const fetchNotificationTypes = async (): Promise<NotificationType[]> => {
  try {
    // Use apiClient instead of axios
    const response = await apiClient.get<NotificationType[]>(NOTIFICATION.getNotificationsTypes)

    // Check if response is not a valid array
    if (!Array.isArray(response.data)) {
      console.error('Invalid notification types response:', response.data)
      // Return an empty array instead of throwing
      return []
    }

    return response.data
  } catch (error) {
    console.error('Error fetching notification types:', error)
    // Return an empty array instead of throwing
    return []
  }
}

/**
 * Transforms API notification objects to the format needed for table display
 * @param {ApiNotification[]} apiNotifications - Raw notifications from the API
 * @returns {TableNotification[]} Transformed notifications for table display
 */
export const transformToTableNotifications = (
  apiNotifications: ApiNotification[],
  notificationTypes: NotificationType[] = []
): TableNotification[] => {
  return apiNotifications.map((notification) => {
    // Use a type-safe approach to find a date property
    let dateString = ''

    // Use an appropriate approach that works with the ApiNotification type
    // First convert to unknown, then to Record to avoid TypeScript errors
    const notificationObj = notification as unknown as Record<string, unknown>

    // Safely check for possible date properties
    if (typeof notificationObj === 'object') {
      // Try various date field naming conventions
      if ('created_at' in notificationObj && typeof notificationObj.created_at === 'string') {
        dateString = notificationObj.created_at
      } else if (
        'created_date' in notificationObj &&
        typeof notificationObj.created_date === 'string'
      ) {
        dateString = notificationObj.created_date
      } else if ('createdAt' in notificationObj && typeof notificationObj.createdAt === 'string') {
        dateString = notificationObj.createdAt
      } else if (
        'creation_date' in notificationObj &&
        typeof notificationObj.creation_date === 'string'
      ) {
        dateString = notificationObj.creation_date
      } else if ('date' in notificationObj && typeof notificationObj.date === 'string') {
        dateString = notificationObj.date
      } else if ('timestamp' in notificationObj && typeof notificationObj.timestamp === 'string') {
        dateString = notificationObj.timestamp
      }
    }

    return {
      ...notification,
      dateHijri: formatHijriDate(),
      dateGregorian: dateString ? formatGregorianDate(dateString) : 'N/A',
      ...(notificationTypes.length > 0 ? { notificationType: notificationTypes } : {})
    }
  })
}

/**
 * Gets the notification type enum value from a type ID
 * @param {number} typeId - Notification type ID
 * @param {NotificationType[]} types - Available notification types
 * @returns {NotificationTypeEnum | undefined} The corresponding notification type enum or undefined if not found
 */
export const getNotificationTypeFromId = (
  typeId: number,
  types: NotificationType[]
): NotificationTypeEnum | undefined => {
  // First check if types array is empty or not provided
  if (types.length === 0) {
    console.warn('No notification types available for mapping, using direct ID mapping')
    return mapCommonTypeIds(typeId)
  }

  // Log the types for debugging
  console.log('Looking for type ID:', typeId, 'in types:', types)

  const typeInfo = types.find((type) => type.id === typeId)

  if (!typeInfo) {
    console.warn(`No notification type found with ID: ${String(typeId)}`)
    return mapCommonTypeIds(typeId)
  }

  // Map the API type_name to the enum
  return mapTypeNameToEnum(typeInfo.type_name)
}

/**
 * Maps common notification type IDs directly to enums
 * @param {number} typeId - The notification type ID
 * @returns {NotificationTypeEnum | undefined} The corresponding enum value or undefined
 */
function mapCommonTypeIds(typeId: number): NotificationTypeEnum | undefined {
  switch (typeId) {
    case 1:
      return NotificationTypeEnum.TASKS_ASSIGNED
    case 2:
      return NotificationTypeEnum.SEASON_UPDATED
    case 3:
      return NotificationTypeEnum.SEASON_APPROVAL
    case 4:
      return NotificationTypeEnum.TASK_DONE
    case 5:
      return NotificationTypeEnum.TASK_APPROVAL
    default:
      return undefined
  }
}

/**
 * Maps a type name string to the NotificationTypeEnum
 * @param {string} typeName - The notification type name
 * @returns {NotificationTypeEnum | undefined} The corresponding enum value or undefined
 */
function mapTypeNameToEnum(typeName: string): NotificationTypeEnum | undefined {
  switch (typeName) {
    case 'TASKS_ASSIGNED':
      return NotificationTypeEnum.TASKS_ASSIGNED
    case 'SEASON_UPDATED':
      return NotificationTypeEnum.SEASON_UPDATED
    case 'SEASON_APPROVAL':
      return NotificationTypeEnum.SEASON_APPROVAL
    case 'TASK_DONE':
      return NotificationTypeEnum.TASK_DONE
    case 'TASK_APPROVAL':
      return NotificationTypeEnum.TASK_APPROVAL
    default:
      console.warn(`Unknown notification type name: ${typeName}`)
      return undefined
  }
}
