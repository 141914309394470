import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { APP_CONFIG } from '@/config/app.config'

// Define types for authentication state
export type UserType = 'SYSTEM_OWNER' | 'BUSINESS_USER' | null

interface AuthState {
  isAuthenticated: boolean
  userType: UserType
  accessToken: string | null
}

interface AuthContextType extends AuthState {
  setAuthState: (state: Partial<AuthState>) => void
  clearAuth: () => void
}

// Create context with default values
const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  userType: null,
  accessToken: null,
  // Use noop functions with comments to avoid ESLint empty function warnings
  setAuthState: () => {
    /* This is intentionally empty - will be replaced by actual implementation */
  },
  clearAuth: () => {
    /* This is intentionally empty - will be replaced by actual implementation */
  }
})

/**
 * Custom hook to access the authentication context
 * @returns {AuthContextType} The authentication context value
 */
export const useAuth = (): AuthContextType => useContext(AuthContext)

interface AuthProviderProps {
  children: ReactNode
}

/**
 * Authentication Provider component that manages user authentication state
 * @param {AuthProviderProps} props - The component props
 * @returns {JSX.Element} The provider component
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    userType: null,
    accessToken: null
  })

  // Initialize auth state from localStorage on mount
  useEffect(() => {
    const { STORAGE_KEYS } = APP_CONFIG.AUTH
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    const userType = localStorage.getItem('USER_TYPE') as UserType

    if (accessToken) {
      setAuthState({
        isAuthenticated: true,
        userType,
        accessToken
      })
    }
  }, [])

  /**
   * Updates the authentication state with new values
   * @param {Partial<AuthState>} newState - The new state values to merge with current state
   */
  const updateAuthState = (newState: Partial<AuthState>) => {
    setAuthState((prevState) => ({
      ...prevState,
      ...newState
    }))
  }

  /**
   * Clears the authentication state, effectively logging out the user
   */
  const clearAuth = () => {
    setAuthState({
      isAuthenticated: false,
      userType: null,
      accessToken: null
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        setAuthState: updateAuthState,
        clearAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
