export const AUTH = {
  SIGN_IN: 'users/login/',
  SIGN_IN_VERIFY: 'users/verify-otp/',
  SIGN_UP: 'users/register-company/',
  SIGN_UP_VERIFY: 'users/register/verify/',
  SIGN_OUT: 'users/logout/'
}

export const COMPANY = {
  completeInfo: (id: string) => `company/complete-info/${id}/`,
  COMPANY_LIST: 'company/list-companies/',
  checkProfileStatus: (id: string) => `users/check-profile-status/${id}/`,
  assignPlan: 'company/assign-plan/',
  COMPANY_UPDATE: (id: string) => `/company/profile/update/${id}/`,
  COMPANY_INFO: (id: string) => `/company/profile/retrieve-info/${id}/`,
  editProfile: (id: string) => `company/complete-info/${id}/`,

  getCompanyProfile: 'company/view-company/',
  //edit company profile:
  UpdateUserInfo: (id: string) => `users/update_user/${id}/`,
  UpdateCompanyInfo: 'company/update-company/',
  UpdateUserEmail: 'users/update-email/'
}

export const USER = {
  register: 'users/register-company/',
  profile: (id: string) => `users/profile/retrieve-info/${id}/`,
  assignPlan: 'users/assign-plan/',
  USER_LIST: 'users/list-users/',
  userInfo: (id: string) => `/users/profile/retrieve-info/${id}/`,
  updateUserInfo: (id: string) => `/users/profile/update/${id}/`,
  addCompany: '/users/add-company/',
  addCompanyLogo: (id: string) => `/users/add-logo/${id}/`
}
export const TASK = {
  get_company_tasks: 'task/list_tasks/',
  get_company_progress: 'company/company-progress/',
  viewTaskDetails: (taskId: string) => `task/detail-task/${taskId}/`, //  endpoint for viewing task details
  approveTask: (taskId: string) => `task/aprrove-task/${taskId}/`, //  endpoint for approving tasks
  rejectTask: (taskId: string) => `task/reject-task/${taskId}/`, //  endpoint for rejecting tasks
  listApproveChoices: 'task/list_approve_choices/', //  endpoint for listing approve choices
  companiesWithProgress: 'task/list_companies/',
  admin_company_tasks: 'task/company_tasks/',
  admin_company_progress: (id: string) => `company/admin-company-progress/${id}/`,

  //company task details
  submitAnswers: (id: string) => `/task/submit-answers/${id}/`,
  uploadFiles: (id: string) => `/task/upload-files/${id}/`,
  removeFile: (id: string) => `/task/file_update/${id}/`,
  update_answers: `/task/bulk_update_answer/`,
  submitTask: (id: string) => `/task/submit_task/${id}/`
}

export const SEASON = {
  seasonList: 'season/list-season/',
  addSeason: 'season/add-season/',
  assignSeason: 'plan/season-details/',
  getSeasonList: '/plan/season-details/',
  getSeasonDetails: (id: string) => `/plan/season-details/${id}/`,
  addBatch: (id: string) => `/plan/season-details/${id}/`,
  approveSeason: (id: string) => `/plan/approve-season/${id}/`
}

export const FILTER_LIST = {
  services: 'plan/list-service/',
  businessModels: 'plan/list-business-model/',
  categories: 'plan/list-phase-category/',
  subCategories: 'plan/list-sub-category/',
  approveChoices: 'task/list_approve_choices/',
  seasons: 'season/list-season/',
  planTypes: 'plan/list-plan-type/'
}

export const PLAN = {
  PLAN_LIST: 'plan/list-plan/',
  ADD_PLAN: 'plan/add-plan/plan-details/',
  GET_PLAN_DETAILS: (id: string) => `plan/details/${id}/`,
  UPDATE_PLAN: (id: string) => `plan/update/${id}/`,
  ASSIGN_PLAN: `plan/assign_plan/`,
  Get_season_detail_list: (id: string) => `plan/company-season-details/${id}`,
  COMPANY_SEASON_DETAILS: (id: string) => `plan/company-season-details/${id}`,
  LIST_UNASSIGNED_PLANS: (companyId: string, seasonDetailId: string) =>
    `plan/list-unassigned-plans/${companyId}/${seasonDetailId}/`
}

export const NOTIFICATION = {
  getNotifications: 'notifications/show_notifications/',
  getNotificationDetails: (id: string) => `notifications/notification_detail/${id}/`,
  getNotificationsTypes: 'notifications/list-notification-types/'
}
