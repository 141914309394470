import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { fetchNotifications } from '@/modules/core/services/notifications.services'
import { useAuth } from '@/modules/core/contexts/AuthContext'
/**
 * Interface defining the shape of the Notification Context
 */
interface NotificationContextType {
  /**
   * The current count of unread notifications
   */
  unreadCount: number

  /**
   * Function to refresh the unread notification count
   * @returns A promise that resolves when the count has been updated
   */
  refreshUnreadCount: () => Promise<void>
}

// Create the context with a default value
const NotificationContext = createContext<NotificationContextType>({
  unreadCount: 0,
  // Provide a no-op implementation that returns a resolved promise
  refreshUnreadCount: async () => Promise.resolve()
})

/**
 * Custom hook to access the notification context
 * @returns The notification context value
 */
export const useNotifications = (): NotificationContextType => useContext(NotificationContext)

/**
 * Props for the NotificationProvider component
 */
interface NotificationProviderProps {
  /**
   * Child components that will have access to the notification context
   */
  children: ReactNode
}

/**
 * Provider component that maintains and updates the unread notification count
 * @param props - The component props
 * @returns A provider component that makes notification data available to its children
 */
export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState<number>(0)
  const { isAuthenticated, userType } = useAuth() // Get authentication state from AuthContext

  /**
   * Fetches notifications and updates the unread count
   * @returns A promise that resolves when the count has been updated
   */
  const refreshUnreadCount = async (): Promise<void> => {
    // Only fetch notifications if the user is authenticated
    if (!isAuthenticated) {
      setUnreadCount(0)
      return
    }

    try {
      const response = await fetchNotifications()

      if (Array.isArray(response.results)) {
        // Count unread notifications
        const totalUnreadCount = response.results.filter((note) => !note.is_read).length
        setUnreadCount(totalUnreadCount)

        console.log({
          userType: userType,
          totalNotifications: response.results.length,
          unreadCount: totalUnreadCount,
          allNotifications: response.results.map((n) => ({
            id: n.id,
            type: n.notification_type,
            isRead: n.is_read,
            message: n.message
          }))
        })
      }
    } catch (error) {
      console.error('Error fetching unread notification count:', error)
    }
  }

  // Initial fetch when component mounts, authentication state changes, or user type changes
  useEffect(() => {
    if (isAuthenticated) {
      // Explicitly mark promise with void operator
      void refreshUnreadCount()

      // Create a non-async wrapper for setInterval
      const refreshWrapper = () => {
        void refreshUnreadCount()
      }

      // Set up periodic refresh (e.g., every 5 minutes)
      const intervalId = setInterval(refreshWrapper, 5 * 60 * 1000)

      return () => {
        clearInterval(intervalId)
      }
    } else {
      // Reset unread count when user is not authenticated
      setUnreadCount(0)
    }
  }, [isAuthenticated, userType])

  return (
    <NotificationContext.Provider value={{ unreadCount, refreshUnreadCount }}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationContext
