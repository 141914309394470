import React from 'react'

// Company information included in notifications
export interface Company {
  company_id: number
  company_arabic_name: string
  company_english_name: string
  logo: string | null
}

// Notification type as returned from the API
export interface NotificationType {
  id: number
  type_name: string
}

// Full notification item from the API
export interface ApiNotification {
  id: number
  sender: number
  receiver: number
  company: Company
  time: string
  gregorian: string
  hijri: string
  message: string
  action: string
  obj_id: number | null
  obj_model: string
  notification_type: number // This is the ID that maps to NotificationType
  description: string
  is_read: boolean
}

// Response shape for the getNotifications endpoint
export interface NotificationsResponse {
  count: number
  next: string | null
  previous: string | null
  results: ApiNotification[]
}

// User roles
export type UserRole = 'SYSTEM_OWNER' | 'BUSINESS_USER'

// Notification type constants - match exactly with backend values
export enum NotificationTypeEnum {
  TASKS_ASSIGNED = 'TASKS_ASSIGNED',
  SEASON_UPDATED = 'SEASON_UPDATED',
  SEASON_APPROVAL = 'SEASON_APPROVAL',
  TASK_DONE = 'TASK_DONE',
  TASK_APPROVAL = 'TASK_APPROVAL'
}

// Map of notification types to user roles they target
export const NOTIFICATION_TARGETS: Record<NotificationTypeEnum, UserRole> = {
  [NotificationTypeEnum.TASKS_ASSIGNED]: 'BUSINESS_USER',
  [NotificationTypeEnum.SEASON_UPDATED]: 'SYSTEM_OWNER',
  [NotificationTypeEnum.SEASON_APPROVAL]: 'BUSINESS_USER',
  [NotificationTypeEnum.TASK_DONE]: 'SYSTEM_OWNER',
  [NotificationTypeEnum.TASK_APPROVAL]: 'BUSINESS_USER'
}

// For readability, use a type alias instead of an empty interface
// This avoids the typescript-eslint/no-empty-object-type error
export type TableNotification = ApiNotification

// Custom table column definition
export interface NotificationColumn {
  key: keyof TableNotification
  header: string
  width: string
  render?: (value: unknown, record: TableNotification) => React.JSX.Element | string
}
